<template>
  <div class="page-wrapper white">
    <div class="page-info">
      <el-row class="page-row w180">
        <el-col class="item">
          <div class="inner">
            <div class="label">学号</div>
            <div class="content">{{ info.username }}（{{ info.name }}）</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">新平板设备ID</div>
            <div class="content">{{ info.pad_uniqueId }}</div>
          </div>
        </el-col>
        <el-col class="item">
          <div class="inner">
            <div class="label">更换设备原因</div>
            <div class="content">{{ info.cause }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="page-tool border">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>
  </div>
</template>
<script>
import { getApi2 } from '@/api'
export default {
	_config:{"route":{"path":"detail","meta":{"title":"查看"}}},
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      getApi2('/operation/student-lock/details', { id: this.$route.query.id }).then(res => {
        this.info = res
      })
    }
  }
}
</script>
